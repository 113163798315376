import axios from "axios";

const clienteAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
 /*  headers: {
    "Access-Control-Allow-Headers": "Content-Disposition",  // Asegúrate de permitir este header
  } */
});

export default clienteAxios;
