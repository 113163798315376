
export const downloadFile = async (resultado) => {

    try {
        //   console.log('resultado.headers =>', resultado.headers); // Verificación de los headers
        const contentDisposition = resultado.headers['content-disposition'];
        // console.log('contentDisposition =>',contentDisposition);
        let fileName = `NoName.xlsx`; // Nombre por defecto
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="([^"]+)"/);
            if (fileNameMatch && fileNameMatch[1]) {
                fileName = fileNameMatch[1];
            }
        }
        console.log("Nombre final del archivo:", fileName);
        const url = URL.createObjectURL(
            new Blob([resultado.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Tipo MIME correcto
            })
        );
        // Crear el enlace para la descarga
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Establecer el nombre final del archivo
        document.body.appendChild(link);
        link.click(); // Hacer clic para descargar el archivo

        // Liberar el objeto URL creado
        URL.revokeObjectURL(url);
    } catch (error) {
        console.log(error);
    }
}