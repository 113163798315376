import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,Link} from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import { actualizarFormaDePago,obtenerFormaDePagoPorId,agregarFormaDePago } from '../services/formasPagoService';

const FormFormaPago = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    codFormaPago: '',
    desFormaPago: '',
    flgEstado: '1', // valor inicial como activo
  });

  useEffect(() => {
    if (id) {  // Solo intenta obtener datos si hay un ID
      const fetchData = async () => {
        try {
          const data = await obtenerFormaDePagoPorId(id);
          setFormValues({
            codFormaPago: data.codFormaPago,
            desFormaPago: data.desFormaPago,
            flgEstado: parseInt(data.flgEstado),
          });
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };
      fetchData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('formValues ->', formValues);
    try {
      if (id) {
        await actualizarFormaDePago(id, formValues);
      } else {
        await agregarFormaDePago(formValues);
      }
      navigate('/formas-de-pago'); // redirige a la página de la tabla
    } catch (error) {
      console.error('Error al guardar los datos:', error);
    }
  };

  return (
    <section className='container'>
      <div className="my-4 text-center container ">
        <h4 className=" fw-bold border border-secondary rounded p-3 shadow mb-2 bg-body text-uppercase">
          Editar forma de pago
        </h4>
      </div>
      <Link to="/formas-de-pago">👈🏼 Regresar</Link>
      <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        margin: 'auto',
        padding: 4,
      }}
    >
      <TextField
        label="Código de Forma de Pago"
        name="codFormaPago"
        value={formValues.codFormaPago}
        onChange={handleChange}
        required
      />
      <TextField
        label="Descripción de Forma de Pago"
        name="desFormaPago"
        value={formValues.desFormaPago}
        onChange={handleChange}
        required
      />
      <Button type="submit" variant="contained" color="primary">
        Guardar
      </Button>
    </Box>

    </section>
   
  );
};

export default FormFormaPago;
