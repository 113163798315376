import clienteAxios from '../../../../../config/axios';

export const obtenerFormaDePagoPorId = async (id) => {
  try {
    const response = await clienteAxios.get(`/api/v1/formas-pagos/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener la forma de pago con ID ${id}:`, error);
    throw error;
  }
};

export const actualizarFormaDePago = async (id, data) => {
  try {
    const response = await clienteAxios.put(`/api/v1/formas-pagos/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar la forma de pago con ID ${id}:`, error);
    throw error;
  }
};

export const agregarFormaDePago = async ( data) => {
  try {
    const response = await clienteAxios.post(`/api/v1/formas-pagos`, data);
    return response.data;
  } catch (error) {
    console.error(`Error al agregar`, error);
    throw error;
  }
};

