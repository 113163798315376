import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import { obtenerFormasDePago } from '../../../../services/apisFichaTecnica';
import { Link, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import { actualizarFormaDePago } from '../services/formasPagoService';

const FormasDePagoTable = () => {
    const [formasDePago, setFormasDePago] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
      const fetchData = async () => {
        const data = await obtenerFormasDePago();
        setFormasDePago(data);
      };
      fetchData();
    }, []);
    const handleEdit = (fila) => {
      navigate(`/formas-de-pago/${fila.idFormaPago}`);
    };
    const enablePay = async(fila) => {
      try {
        const dataForApi ={
          codFormaPago:fila.codFormaPago,
          desFormaPago:fila.desFormaPago,
          flgEstado:1
        }
        await actualizarFormaDePago(fila.idFormaPago, dataForApi);
        const data = await obtenerFormasDePago();
        setFormasDePago(data);
      } catch (error) {
        Swal.fire('Error', 'Comportamiento inesperado.', 'error');
      }
    };
    const handleDelete = async(fila) => {
      try {
        const dataForApi ={
          codFormaPago:fila.codFormaPago,
          desFormaPago:fila.desFormaPago,
          flgEstado:0
        }
        await actualizarFormaDePago(fila.idFormaPago, dataForApi);
        Swal.fire('Inactivado', 'La forma de pago ha sido inactivada.', 'success');
        const data = await obtenerFormasDePago();
        setFormasDePago(data);
      } catch (error) {
        Swal.fire('Error', 'Comportamiento inesperado.', 'error');
      }
    };

    const confirmDelete = (fila) => {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Quieres inactivar esta forma de pago?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, inactivar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(fila);
       
        }
      });
    };
  
    const columns = [
      { field: 'idFormaPago', headerName: 'ID', flex: 1 },
      { field: 'codFormaPago', headerName: 'Código', flex: 1 },
      { field: 'desFormaPago', headerName: 'Descripción', flex: 2 },
      {
        field: 'flgEstado',
        headerName: 'Estado',
        flex: 1,
        renderCell: (params) => (params.value === "1" ? "Activo" : "Inactivo"),
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        flex: 1,
        renderCell: (params) => (
        <>
          <IconButton
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          sx={{ marginLeft: 1 }}
          onClick={() => confirmDelete(params.row)}
        >
          <ClearIcon />
        </IconButton>
        <IconButton
          color="success"
          sx={{ marginLeft: 1 }}
          onClick={() => enablePay(params.row)}
        >
          <CheckIcon />
        </IconButton>
        </>
        ),
      },
    ];
  
    return (
     <section className="container-fluid pt-4">

<div className="my-4 text-center container ">
  
        <h4 className=" fw-bold border border-secondary rounded p-3 shadow mb-2 bg-body text-uppercase">
          Formas de Pago
        </h4>
      </div>
      <div
      style={{display:'flex', width:'100%',justifyContent:'end'}}
      >
  <Link to="/agregar-forma-de-pago" className='btn btn-primary mb-3'>Agregar forma de pago 📄</Link>
  </div>
         <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={formasDePago}
          columns={columns}
          getRowId={(row) => row.idFormaPago}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          autoPageSize
        />
      </div>
     </section>
    );
  };
  
  export default FormasDePagoTable;