import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { obtenerModulosSistemasUsuariosTiposReceptores, obtenerTiposReceptores, editarModulosSistemasUsuariosTiposReceptores } from "../services/administracionCorreosService";
import "./administracionCorreos.css";
import Swal from 'sweetalert2';
const ModuleSistemUsersTypesReceptors = () => {
    const { id } = useParams();
    const location = useLocation();
   // console.log('location =>',location.state.nombreModulo);
    //const nombreModulo = location.state?.nombreModulo;
    const idGeneral = id;
    const [receptors, setReceptors] = useState([]);
    const [tipoReceptores, setTipoReceptores] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;


    useEffect(() => {
        const fetchReceptors = async () => {
            try {
                const data = await obtenerModulosSistemasUsuariosTiposReceptores(id);
                setReceptors(data);
            } catch (error) {
                console.error("Error al obtener los tipos de receptores:", error);
            }
        };

        const fetchTiposReceptores = async () => {
            try {
                const data = await obtenerTiposReceptores();
                setTipoReceptores(data);
            } catch (error) {
                console.error("Error al obtener los tipos de receptores:", error);
            }
        };

        fetchReceptors();
        fetchTiposReceptores();
    }, [id]);

    const handleTipoReceptorChange = (event, receptorId) => {
        const newTipoReceptor = event.target.value;
        setReceptors((prevReceptors) =>
            prevReceptors.map((receptor) =>
                receptor.idModuloSistemaUsuarioTipoReceptor === receptorId
                    ? { ...receptor, idTipoReceptor: newTipoReceptor }
                    : receptor
            )
        );
    };

    const guardar = async (item) => {
        try {
            const id = item.idModuloSistemaUsuarioTipoReceptor;
            const dataApi = {
                idTipoReceptor: item.idTipoReceptor
            }
            await editarModulosSistemasUsuariosTiposReceptores(id, dataApi);
            Swal.fire('Guardado', 'Se a guardado con exito.', 'success');
            const data = await obtenerModulosSistemasUsuariosTiposReceptores(idGeneral);
            setReceptors(data);
        } catch (error) {
            console.error("Error al obtener al guardar:", error);
            Swal.fire('Error', 'Comportamiento inesperado.', 'error');
        }
    }
    // Paginación
    const totalPages = Math.ceil(receptors.length / rowsPerPage);
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = receptors.slice(indexOfFirstRow, indexOfLastRow);

    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    return (
        <section className="container-fluid pt-4">
            <div className="my-4 text-center container ">
                <h4 className=" fw-bold border border-secondary rounded p-3 shadow mb-2 bg-body text-uppercase">
                {location.state.nombreModulo}
                </h4>
            </div>

            <div className="table-container">
                <Link to="/administracion-correos">👈🏼 Regresar</Link>
                <table className="custom-table mt-3">
                    <thead>
                        <tr>
                            <th>Nombre Completo</th>
                            <th>  {location.state.nombreModulo}</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((receptor) => (
                            <tr key={receptor.idModuloSistemaUsuarioTipoReceptor}>
                                <td>{`${receptor.nombreUsuario} ${receptor.apellidoUsuario}`}</td>
                                <td>
                                    <select
                                        value={receptor.idTipoReceptor}
                                        onChange={(event) => handleTipoReceptorChange(event, receptor.idModuloSistemaUsuarioTipoReceptor)}
                                        className="custom-select"
                                    >
                                        {tipoReceptores.map((tipo) => (
                                            <option key={tipo.idTipoReceptor} value={tipo.idTipoReceptor}>
                                                {tipo.desTipoReceptor}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button className="table__btnSuccess"
                                        onClick={() => guardar(receptor)}
                                    >Guardar</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                        Anterior
                    </button>
                    <span>
                        Página {currentPage} de {totalPages}
                    </span>
                    <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                        Siguiente
                    </button>
                </div>
            </div>
        </section>
    );
};

export default ModuleSistemUsersTypesReceptors;
