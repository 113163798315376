import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { obtenerModulosSistemas } from "../services/administracionCorreosService";

const ModuleSistemList = () => {
    const [modules, setModules] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchModules = async () => {
            try {
                const data = await obtenerModulosSistemas();
                const filteredModules = data.filter(module => module.flgEnvioCorreo === "1");
                setModules(filteredModules);
            } catch (error) {
                console.error("Error al obtener los módulos del sistema:", error);
            }
        };

        fetchModules();
    }, []);

    const handleRowClick = (params) => {
        console.log('params rutaModulo ===>',params.row.nombreModulo);
        navigate(`/administracion-correos/${params.id}`, { state: { nombreModulo: params.row.nombreModulo } });
    };

    const columns = [
       
        { field: "nombreModulo", headerName: "Nombre Módulo", flex: 1 },
       /*  { field: "rutaModulo", headerName: "Ruta Módulo", flex: 1 }, */
        { field: "moduloGlobal", headerName: "Módulo Global", flex: 1 },
    ];

    return (
        <section className="container-fluid pt-4">

            <div className="my-4 text-center container ">
                <h4 className=" fw-bold border border-secondary rounded p-3 shadow mb-2 bg-body text-uppercase">
                    Administracion correos
                </h4>
            </div>

            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={modules.map((module) => ({
                        id: module.idModulosistema,
                        nombreModulo: module.nombreModulo,
                        rutaModulo: module.rutaModulo,
                        moduloGlobal: module.moduloGlobal,
                        ordenMenu: module.ordenMenu,
                    }))}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onRowClick={handleRowClick}
                    disableSelectionOnClick
                    autoPageSize
                />
            </div>
        </section>
    );
};

export default ModuleSistemList;
