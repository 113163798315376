import clienteAxios from "../../../../../config/axios";

export const obtenerModulosSistemas = async (id) => {
    try {
      const response = await clienteAxios.get(`/api/v1/modulos-sistemas`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener la forma de pago con ID ${id}:`, error);
      throw error;
    }
  };

export const obtenerModulosSistemasUsuariosTiposReceptores = async (id) => {
    try {
      const response = await clienteAxios.get(`/api/v1/modulos-sistemas/${id}/modulos-sistemas-usuarios-tipos-receptores`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener la forma de pago con ID ${id}:`, error);
      throw error;
    }
  };

export const editarModulosSistemasUsuariosTiposReceptores = async (id,data) => {
    try {
      const response = await clienteAxios.put(`/api/v1/modulos-sistemas-usuarios-tipos-receptores/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener la forma de pago con ID ${id}:`, error);
      throw error;
    }
  };

export const obtenerTiposReceptores= async (id) => {
    try {
      const response = await clienteAxios.get(`/api/v1/tipos-receptores`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener la forma de pago con ID ${id}:`, error);
      throw error;
    }
  };