import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../context/autenticacion/authContext";
import Logo from "../img/relixsinfondo.png";
import Swal from "sweetalert2";
import clienteAxios from "../config/axios";
import { downloadFile } from "../shared/services/utils/download";
function SideBarMenu({ activarSideBar, setActivarSideBar, usuario }) {
  const { rutas } = usuario;
  //console.log('ver rutas',rutas)
  let navigate = useNavigate();
  /////////////////////////////////
  const modulosAgrupados = {};

  rutas.forEach((item) => {
    if (!modulosAgrupados[item.moduloGlobal]) {
      modulosAgrupados[item.moduloGlobal] = [];
    }
    modulosAgrupados[item.moduloGlobal].push({
      nombreModulo: item.nombreModulo,
      rutaModulo: item.rutaModulo,
      icon: item.icon,
    });
  });
  //console.log('modulosAgrupados ->', modulosAgrupados);
  const autentificaciones = useContext(authContext);
  const { cerrarSesion } = autentificaciones;
  const btnCerrarSesion = async () => {
    // console.log("se cierra");
    const accionUsuario = await Swal.fire({
      icon: "warning",
      title: "¿Esta seguro de cerrar sesion?",
      showConfirmButton: true,
      showCancelButton: true,
    });

    if (accionUsuario.isConfirmed) {
      cerrarSesion();
      navigate(`/`);
    }
  };
  const reportePepiline = async () => {
    try {
      var config = {
        responseType: "arraybuffer",
      };
      const resultado = await clienteAxios.get(
        `/api/exportarFichasTecnicas`,
        config
      );
      downloadFile(resultado)
    } catch (error) {
      console.log(error.response.data.messages.error);
    }
  };
  const btnReportePipeLine = (modulo) => {
    if (modulo.nombreModulo == "Reporte Pipeline") {
      reportePepiline();
      return;
    }

    return;
  };
  
  return (
    <div className={!activarSideBar ? "navDesign" : "navDesign show-menu"}>
      <nav className="navDesign__container">
        <div>
          <Link to="/sesioniniciada" className="navDesign__link navDesign__logo">
            <img src={Logo} alt="logo relix" className="header__img" />
          </Link>

          <div className="navDesign__list">
            <div className="navDesign__items">
              <h3 className="navDesign__subtitle">Perfil</h3>

              <Link to="/sesioniniciada" className="navDesign__link active">
                <i className="bx bx-home navDesign__icon"></i>
                <span className="navDesign__name">Inicio</span>
              </Link>
         {/*      {moduloSistemas.length > 0 &&
                moduloSistemas.map((item, i) => (
                  <Link
                    to={`/${item.rutaModulo}`}
                    className="navDesign__link "
                    key={i}
                    onClick={() => setActivarSideBar(!activarSideBar)}
                  >
                    <i className="bx bxs-user-check navDesign__icon"></i>
                    <span className="navDesign__name">{item.nombreModulo}</span>
                  </Link>
                ))} */}

              {/*     DROPDOWN */}
                <>
                  {Object.entries(modulosAgrupados).map(([moduloGlobal, modulos], index) => (
                    <div className="navDesign__dropdown" key={index}>
                      <a href="#" className="navDesign__link">
                        <i className={`${modulos[0].icon} navDesign__icon`}></i>
                        <span className="navDesign__name">{moduloGlobal}</span>
                        <i className="bx bxs-chevron-down navDesign__icon navDesign__dropdown-icon"></i>
                      </a>

                      <div className="navDesign__dropdown-collapse">
                        <div className="navDesign__dropdown-content">
                          {modulos.map((modulo, i) => (
                            <Link
                              to={`/${modulo.rutaModulo}`}
                              className="navDesign_dropdown-item"
                              key={i}
                              onClick={() => {
                                setActivarSideBar(!activarSideBar)
                                btnReportePipeLine(modulo);
                              }}
                            >
                              <span className="navDesign__name">{modulo.nombreModulo}</span>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </>



            </div>
          </div>
        </div>

        <Link to="" className="navDesign__link navDesign__logout" onClick={btnCerrarSesion}>
          <i className="bx bxs-log-out navDesign__icon"></i>
          <span className="navDesign__name">Cerrar Sesion</span>
        </Link>
      </nav>
    </div>
  );
}

export default SideBarMenu;
